<script setup>
import { defineEmits, ref, defineProps } from 'vue';
import AdaptableButton from './AdaptableButton.vue';

const emits = defineEmits(['update:packageSelected', 'update:viewVoucher', 'update:lockerType', 'update:step']);

const props = defineProps({
    typeLockersAvailable: {
    type: [[Object]],
    required: true,
  }
});

//variable de usabilidad
const selected = ref('');
const sizeName = ref('');

//fuciones emit 
const toSelectPackage = (size, name) => {
    selected.value = size;
    sizeName.value = name;
    emits('update:lockerType', sizeName.value);
            
}

const toSavePackage = () => {
    if(selected.value && sizeName){
        emits('update:packageSelected', selected.value);
        emits('update:viewVoucher', 'ok');
        emits('update:step', 5);
    }
}

//funciones de utilidad
const formatTypeLockersObject = (inputObject) => {
    // Define the desired keys
    const desiredKeys = ['s', 'm', 'l'];

    // Create a new object with the desired keys and their corresponding values
    const filteredObject = {};
    desiredKeys.forEach(key => {
        filteredObject[key] = inputObject["content"][key] || null;
        // filteredObject[key] = inputObject[key] || null;
    });

    return filteredObject;

}

</script>

<template>
    <div class="flex flex-col">
        <div class="text-center">
            <h2 class="text-xl font-semibold text-white"> Elegir tamaño de </h2>
            <h2 class="mb-5 font-semibold text-xl text-white"> paquete </h2>
        </div>
        <div class="space-y-4">
            <AdaptableButton
                v-for="(buttonId, index) in formatTypeLockersObject(props['typeLockersAvailable'])"
                :key="index"
                :text="index.toString().toLowerCase() === 's' ? 'Chico' : index.toString().toLowerCase() === 'm' ? 'Mediano' :
                        index.toString().toLowerCase() === 'l' ? 'Grande' : index.toString().toLowerCase() === 'xl' ? 'Extra Grande' :
                        'XExtra Grande' "
                :bgColor="(selected == buttonId && buttonId !== null) ? 'green-500' : buttonId == null ? 'gray-300' :  '[#FFB800]'"
                :disabled="buttonId === null"
                :textColor="buttonId !== null ? 'black' : 'gray-500'"
                :labelText="buttonId === null ? 'No disponible' : ''"
                @click="toSelectPackage(buttonId, index.toString().toLowerCase() === 's' ? 'Chico' : index.toString().toLowerCase() === 'm' ? 'Mediano' :
                        index.toString().toLowerCase() === 'l' ? 'Grande' : index.toString().toLowerCase() === 'xl' ? 'Extra Grande' :
                        'XExtra Grande')"
            />

            <AdaptableButton
                :text="'Siguiente'"
                :bgColor="'black'"
                :textColor="'white'"
                @input-function="toSavePackage"
            /> 

        </div>
        
    </div>
</template>