<script setup>

</script>

<template>
    <div class="flex flex-row justify-between items-center">
        <div class="grow"> </div>
        <div class="bg-blue-400 grow place-content-center rounded-full flex p-10 flex-col items-center">
            <img src="../assets/close.png" class="rounded-md max-h-20 max-w-20 object-center"/>
            <p class="text-white text-xl text-center sm:text-2xl font-bold">Apertura fallida. Locker sin conexión. (Reserva seguirá vigente)</p>
        </div>
        <div class="grow"> </div>
    </div>
</template>