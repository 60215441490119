<template>
  <div class="flex flex-col items-center justify-center">
    <img
      src="../assets/locker_2.png"
      alt="Invitation Image"
      class="mb-4 h-20"
    />
    <h1 class="text-3xl font-bold mb-4">{{ title }}</h1>
    <p class="text-lg mb-6 text-center">{{ message }}</p>
    <p class="text-lg mb-6 text-center font-bold" >{{ `N° Locker: ${this.$route.query.locker}` }}</p>
    <div class="flex space-x-4">
      <button
        :class="[loading ? 'bg-white' : 'bg-green-500 hover:bg-green-600 ']"
        class="min-h-content text-white font-medium py-2 px-4 rounded-md flex justify-center items-center"
        @click="openModal"
      >
        <div class="h-8">
          <div
            class="animate-spin rounded-full border-t-2 min-h-full min-w-full aspect-square border-b-2 border-[#0064ff]"
            v-if="loading"
          ></div>
          <span class="align-middle" v-else>
            {{ acceptButtonText }}
          </span>          
        </div>
      </button>
      <button 
        @click="copyLink"
        class="flex relative items-center"
        >
        <img 
          class="h-12 w-12 bg-[#0064ff] hover:bg-blue-500 p-1 rounded-lg " 
          src="../assets/copy.png" 
        />
        <h3 
          v-if="copyMessage" 
          class="absolute pl-12"
        > 
          {{ copyMessage }} 
        </h3>
      </button>
    </div>
    <RentModal
      @send="accept"
      @cancel="hideModal"
      :isOpen="this.showModal"
      :loadingModal="this.loadingModal"
      :modalTitle="`¿Desea abrir el locker ${this.$route.query.locker} en la instalación: ${this.$route.query.instalacion}?`"
      :msg="`Esta es una invitación de un solo uso, una vez ocupada no podrá volver a ser utilizada.`"
    />      
  </div>
</template>

<script>
import RentModal from '@/components/ui/RentModal.vue'

export default {
  props: {
    title: {
      type: String,
      default: 'Invitación'
    },
    message: {
      type: String,
      default:
        'Un usuario está compartiendo este locker contigo ¿Deseas abrirlo?'
    },
    acceptButtonText: {
      type: String,
      default: 'Abrir Locker'
    }
  },
  components: {
    RentModal
  },
  data () {
    return {
      loading: false,
      timeoutTimer: null,
      showModal: false,
      loadingModal: false,
      socket: null,
      copyMessage: ''
    }
  },
  created () {
    this.socket = new WebSocket(process.env.VUE_APP_URL_SOCKET)
    const vm = this
    this.socket.onmessage = (e) => {
      try {
        const jsonData = JSON.parse(e.data)
        if (jsonData.messageDevice && jsonData.code == 200) {
          clearTimeout(vm.timeoutTimer);
          vm.$router.push({ name: 'SuccessOpenView' })
        } else if (jsonData.code == 409){
          clearTimeout(vm.timeoutTimer);
          vm.$router.push({ name: 'SpentCodeView' });
        } else if (jsonData.code == 400){
          clearTimeout(vm.timeoutTimer);
          this.$router.push({ name: 'FailureOpenView' });
        } else if (jsonData.code == 403){
          clearTimeout(vm.timeoutTimer);
          this.$router.push({ name: 'LockerOffLineView' });
        }
      } catch (error) {
        if (error instanceof SyntaxError) {
          console.log(
            'JSON string is not in the correct format:',
            error.message
          )
        } else {
          console.log('Error parsing JSON:', error.message);
        }
      }
    }
  },
  computed: {
    currentUrl() {
      // Accede a la URL actual desde la propiedad $route del enrutador de Vue
      return  `${process.env.VUE_APP_URL_FRONTEND}${this.$route.fullPath}`;
    }
  },
  methods: {
    openModal () {
      this.showModal = true;
    },
    hideModal () {
      this.showModal = false;
    },
    accept () {
      const request = {
        usuario: 'Empty',
        mesasageRequest: 'test websocket',
        type: 'apertura_cliente', // reserva termina con 2 aperturas
        clave: this.$route.query.code,
        chapa: {
          id: this.$route.query.device,
          nro_chapa: this.$route.query.locker
        },
        nro_serie: this.$route.query.device,
        token: "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJnYWJyaWVsLmNhbXBvcy5hYnVydG9AZ21haWwuY29tIiwiY29ycmVvIjoiZ2FicmllbC5jYW1wb3MuYWJ1cnRvQGdtYWlsLmNvbSIsInJvbGVzIjpbIlJPTEVfU1VQRVJBRE1JTiJdLCJpbnN0YWxhY2lvbiI6IjEiLCJmZWNoYUV4cGlyYWNpb24iOjE3MjU0MTY2OTUwMzB9.8wUYkkSCUpkZNGofSN_nGcXlBLmMCL9-uhCaQq1xoBMaiR0EJqdeXn-jrL81tsPXUeK6cX50D0E8u7GEDJl0qw"
      }
      this.socket.send(JSON.stringify(request));
      this.hideModal()
      this.loading = true;
      this.timeoutTimer = setTimeout(this.redirectToFailView, 20000);
    },
    redirectToFailView: function () {
      this.$router.push({ name: 'FailureOpenView' });
    },
    copyLink () {
      navigator.clipboard.writeText(this.currentUrl);
      this.copyMessage = 'Link copiado!';
      setTimeout(() => {
        this.copyMessage = '';
      }, 3000);
    },
  }
}
</script>
