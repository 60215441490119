<script setup>
import { ref,  watch, defineEmits, onMounted, defineProps, toRaw } from 'vue';
import axios from 'axios';

import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import {  CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

const emits = defineEmits(['update:deptoSelected', 'update:step', 'update:nroDpto']);

const props = defineProps({
    intalationSelected: {
        type: [Number],
        required: true,
    }
});

const toSelectPackage = () => {
    emits('update:nroDpto', toRaw(selected.value));
    emits('update:deptoSelected', deptos.value[toRaw(selected.value)]);
    emits('update:step', 3);    
}

const isLoading = ref(true);

const people = ref([]);
const deptos = ref({});

let selected = ref(people.value[0]);

watch(selected, () => {
    toSelectPackage();
})

onMounted(async () => {
    const urlDept = `${process.env.VUE_APP_URL_BACKEND}/departamentos/instalacion/${props.intalationSelected}`;
    await axios
        .get(urlDept)
        .then((depts) => {
            people.value = depts.data;
            depts.data.forEach(element => {
                if(deptos.value[element?.nro_depto]?.length > 0){
                    deptos.value[element?.nro_depto]?.push({nombre: element?.nombre, correo: element?.correo});
                } else {
                    deptos.value[element?.nro_depto] = [{nombre: element?.nombre, correo: element?.correo}];
                }
            isLoading.value = false;
            });
        })
        .catch(() => {
            people.value = [];
        });
    
});

</script>

<template>
    <div>
        <div class="text-center">
            <h2 class="text-xl font-semibold text-white"> Seleccionar</h2>
            <h2 class="mb-5 font-semibold text-xl text-white"> Lugar </h2>
            
        </div>
        <Listbox v-model="selected" :disabled="isLoading" class="mt-10">
            <div class="relative mt-1">
                <ListboxButton
                    class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                    <span v-if="!isLoading" class="block truncate">{{ selected ? selected : "Selecciona un lugar" }}</span>
                    <!-- spinner -->
                    <div v-if="isLoading" class="flex justify-center items-center">
                        <div
                        class="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-blue-500"
                        ></div>
                    </div>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>
                
                <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption v-slot="{ active, selected }" v-for="(dept, keyValue) in deptos" :key="keyValue"
                          :value="keyValue" as="template">
                          <li :class="[
                          active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-2 pr-2',
                          ]">
                          <span :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                          ]">{{ keyValue }}</span>
                          <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                          </li>
                      </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        
    </div>
</template>