<script setup>
import { ref,  watch, defineEmits, defineProps } from 'vue'; 
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue';
import {  CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

const emits = defineEmits(['update:userSelected', 'update:userName', 'update:step']);

const props = defineProps({
    deptoSelected: {
        type: [Object],
        required: true,
    }
});

const toSelectPackage = () => {
    // para voucher
    emits('update:userName', selected.value.nombre);
    // correo de la persona
    emits('update:userSelected', selected.value.correo);
    emits('update:step', 4);
}

const people = ref([]);

let selected = ref(people.value[0]);

watch(selected, () => {
    toSelectPackage();
})

</script>

<template>
    <div>
        <div class="text-center">
            <h2 class="text-xl font-semibold text-white"> Seleccionar </h2>
            <h2 class="mb-5 font-semibold text-xl text-white"> Usuario </h2>
        </div>
        
        <Listbox v-model="selected" class="mt-10">
            <div class="relative mt-1">
                <ListboxButton
                    class="relative border w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
                    <span class="block truncate">{{ selected ? selected["nombre"] : "Selecciona un destinatario" }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                </ListboxButton>
                <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      class="absolute mt-1 max-h-60 w-full z-20 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption v-slot="{ active, selected }" v-for="people in props.deptoSelected" :key="people"
                          :value="people " as="template">
                          <li :class="[
                          active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-2 pr-2',
                          ]">
                          <span :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                          ]"> {{ people["nombre"] }} </span>
                          <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                              <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                          </li>
                      </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>