import router from '..'
import getExpiration from './getExpiration'
export default function auth () {
  const token = localStorage.token
  const expiration = getExpiration(token)
  if (!localStorage.user || !localStorage.token || expiration < Date.now()) {
    router.push({ name: 'Login' })
    return false
  }
  return true
}
