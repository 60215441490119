<script setup>
import { defineProps, onMounted, ref } from 'vue';

const props = defineProps({
    numeroLocker: {
        type: String,
        required: true,
    },
    nroDpto: {
        type: String,
        required: true,
    }
});

// Valor de countdown para vista
const countdown = ref(30);

const redirectToURL = () => {
    window.location.href = "https://odihnx.com/"; 
};

// Funcion de timer, baja countdown cada intervalo de 1000
const startRedirectTimer = () => {
    const timer = setInterval(() => {
        if (countdown.value > 0) {
            countdown.value--;  // Baja countdown en vista
        }
    }, 1000);

    // Redireccion cuento timer llega a 0
    setTimeout(() => {
        clearInterval(timer);  // Se borra intervalo
        redirectToURL(); 
    }, 30000);  // 60 segundos
};

// Inicia tiempo cuando se monta componente
onMounted(() => {
    startRedirectTimer(); 
});

</script>

<template>
    <div>
        <div class="py-8 grid justify-items-center text-center text-black">
            <h2 class="mb-10 text-xl font-semibold text-white"> Guardar pedido </h2>
            <h3 class="text-md font-semibold text-white"> 
                N° Locker: <span class="ml-4"></span> {{ props.numeroLocker }}  </h3>
            <h3 class="text-md font-semibold text-white"> 
                    N° Departamento: <span class="ml-4"></span> {{ props.nroDpto }}  </h3>
            <img 
                class="mt-6 block h-16 w-16 cursor-pointer" 
                src="@/assets/package.png"
            />
            <h3 class="mt-8 text-md font-semibold text-white"> Se notificará al usuario que el  </h3>
            <h3 class="text-md font-semibold text-white"> pedido ha sido entregado!  </h3>
            <h3 class="mt-8 text-md font-semibold text-white"> Asegúrese de cerrar el locker.  </h3>
            <!-- Display countdown to redirect -->
            <h3 class="mt-8 text-md font-semibold text-white"> Redirigiendo en {{ countdown }} segundos... </h3>
        </div>
    </div>
</template>